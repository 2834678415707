import React, { useState, useEffect } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { Box } from '@mui/material'
import apiClient from '../../../clients/ApiClient'
import io from 'socket.io-client'

// const socket = io('http://api.asconlineportal.com/api') // Replace with your backend's WebSocket URL
const socket = io('https://api.asconlineportal.com', {
    path: '/socket.io/', // Default Socket.IO path
    transports: ['websocket'], // Use WebSocket transport
  });
   // Adjust as needed

const OTAttendanceTodayList = () => {
    const [employees, setEmployees] = useState([]) // State to store employee data
    const [loading, setLoading] = useState(true) // Loading state

    // Fetch DTR data from the API
    const fetchDtrData = async () => {
        try {
            const today = new Date().toISOString().slice(0, 10)

            const attendance = {
                dateStart: today,
                dateEnd: today
            }

            const response = await apiClient.get('/overtime/', {
                params: attendance
            })

            const formattedData = response.data
                .map((employee, index) => {
                    const record = employee.dailyTimeRecord && employee.dailyTimeRecord[0]
                    if (!record) {
                        return null
                    }

                    return {
                        id: index + 1,
                        name: employee.fullName,
                        timeIn: record.timeIn === null ? '-' : new Date(record.timeIn).toLocaleTimeString(),
                        timeOut: record.timeOut === null ? '-' : new Date(record.timeOut).toLocaleTimeString(),
                        remarks: record.remarks === null ? '-' : record.remarks
                    }
                })
                .filter(Boolean)

            setEmployees(formattedData)
            setLoading(false)
        } catch (error) {
            console.error('Error fetching DTR data:', error)
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchDtrData()

        socket.on('otAttendanceUpdated', (updatedAttendance) => {
            console.log('Attendance Updated:', updatedAttendance)
            fetchDtrData()
        })

        return () => {
            socket.off('otAttendanceUpdated')
        }
    }, [])

    // Define columns for the DataGrid
    const columns = [
        { field: 'name', headerName: 'Name', width: 250 },
        { field: 'timeIn', headerName: 'Time In', width: 150 },
        { field: 'timeOut', headerName: 'Time Out', width: 150 },
        {
            field: 'remarks',
            headerName: 'Remarks',
            width: 150,
            renderCell: (params) => {
                const remark = params.value
                const bgColor = remark === 'Home' ? 'bg-red-100 text-red-700' : 'bg-blue-100 text-blue-700'
                return <span className={`px-3 py-1 rounded-full font-semibold ${bgColor}`}>{remark}</span>
            }
        }
    ]

    return (
        <Box sx={{ height: '75%', width: 700 }}>
            <h2 className="text-xl font-semibold mb-4">Overtime Attendance Today</h2>
            <DataGrid
                className="bg-white"
                rows={employees}
                columns={columns.map((column) => ({
                    ...column,
                    headerClassName: 'header-bold'
                }))}
                pageSize={10}
                rowsPerPageOptions={[10]}
                loading={loading}
                disableSelectionOnClick
                sx={{
                    '& .MuiDataGrid-columnHeaders': {
                        fontWeight: 'bold'
                    },
                    '& .MuiDataGrid-cell': {
                        cursor: 'default',
                        pointerEvents: 'none'
                    }
                }}
            />
        </Box>
    )
}

export default OTAttendanceTodayList
