import axios from 'axios'
import apiClient from '../clients/ApiClient' // Update this import path as needed


export const fetchAuditLogs = async (filter) => {
    try {
        const response = await apiClient.get('/audit/', { params: filter })
        return response.data
    } catch (error) {
        let errorMessage = 'Unexpected error occurred'

        if (axios.isAxiosError(error)) {
            if (error.response) {
                // Error response from backend
                errorMessage = error.response.data?.message || 'Unexpected error occurred.'
            } else if (error.message.includes('ERR_CONNECTION_REFUSED')) {
                // Handle connection refused explicitly
                errorMessage = 'Connection refused. Please check the backend server.'
            }
            console.error('Axios error:', error.response?.status, error.response?.data)
        } else {
            console.error('Unexpected error:', error)
            errorMessage = 'An unexpected error occurred: ' + error.message
        }

        return { error: errorMessage } // Return error message in an object
    }
}
