import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Layout from './components/shared/Layout'
import Login from './components/Login'
import AttendanceManager from './components/attendance/clock-in-out/AttendanceManager'
import AttendanceLayout from './components/attendance/dtr/DailyTimeRecordLayout'
import ProtectedRoute from './helper/routes/ProtectedRoute'
import ReportComponent from './components/finance/ReportLayout'
import { UserProvider } from './helper/routes/UserContext'
import Unauthorized from './helper/UnauthorizedScreen'
import OTAttendanceManager from './components/attendance/ot/OTAttendanceManager'
import LeaveLayout from './components/attendance/leave/LeaveLayout'
import OvertimeRequestList from './components/management/OvertimeRequestList'
import QRAttendanceActions from './components/attendance/qr-scanner/QRAttendanceActions'
import EmployeeList from './components/employee/EmployeeList'
import EmployeeInfo from './components/employee/EmployeeInfo'
import UserRoleList from './components/role/UserRoleList'
import UserAccountList from './components/user-account/UserAccountList'
import UserAccountInfo from './components/user-account/UserAccountInfo'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import PositionList from './components/position/PositonList'
import WebAuthApp from './components/webauth/webauth'
import AuditLogList from './components/audit-logs/AuditLogList'
import MyProfile from './components/shared/profile/MyProfile'

function App() {
    const theme = createTheme({
        palette: {
            primary: {
                main: '#d11919' // Customize your primary color here
            },
            secondary: {
                main: '#0049db' // Customize your secondary color here (optional)
            }
        }
    })

    return (
        <UserProvider>
            <ThemeProvider theme={theme}>
                <Router>
                    <Routes>
                        <Route path="login" element={<Login />} /> {/* Public route */}
                        <Route path="webAuthApp" element={<WebAuthApp />} /> {/* Public route */}
                        <Route path="attendance/scan" element={<QRAttendanceActions />} /> {/* Public route */}
                        <Route
                            path="/"
                            element={
                                <ProtectedRoute allowedRoles={['super admin', 'employee', 'finance', 'management']}>
                                    <Layout />
                                </ProtectedRoute>
                            }
                        >
                            <Route
                                index
                                element={
                                    <ProtectedRoute allowedRoles={['super admin', 'employee', 'finance', 'management']}>
                                        <AttendanceManager />
                                    </ProtectedRoute>
                                }
                            />
                            {/* Protected route */}
                            <Route
                                path="attendance/dtr"
                                element={
                                    <ProtectedRoute allowedRoles={['super admin', 'employee', 'finance', 'management']}>
                                        <AttendanceLayout />
                                    </ProtectedRoute>
                                }
                            />
                            {/* Protected route */}
                            <Route
                                path="attendance/ot"
                                element={
                                    <ProtectedRoute allowedRoles={['super admin', 'employee', 'finance', 'management']}>
                                        <OTAttendanceManager />
                                    </ProtectedRoute>
                                }
                            />
                            {/* Protected route */}
                            <Route
                                path="attendance/leave"
                                element={
                                    <ProtectedRoute allowedRoles={['super admin', 'employee', 'finance', 'management']}>
                                        <LeaveLayout />
                                    </ProtectedRoute>
                                }
                            />
                            {/* Protected route */}
                            <Route
                                path="management/overtime-approval"
                                element={
                                    <ProtectedRoute allowedRoles={['super admin', 'management']}>
                                        <OvertimeRequestList />
                                    </ProtectedRoute>
                                }
                            />
                            {/* Protected route */}
                            <Route
                                path="finance/generate-dtr"
                                element={
                                    <ProtectedRoute allowedRoles={['finance']}>
                                        <ReportComponent />
                                    </ProtectedRoute>
                                }
                            />
                            {/* Protected route */}
                            <Route
                                path="employee"
                                element={
                                    <ProtectedRoute allowedRoles={['finance', 'super admin']}>
                                        <EmployeeList />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="employee/info/:employeeId"
                                element={
                                    <ProtectedRoute allowedRoles={['finance', 'super admin']}>
                                        <EmployeeInfo />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="roles"
                                element={
                                    <ProtectedRoute allowedRoles={['super admin']}>
                                        <UserRoleList />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="user-account"
                                element={
                                    <ProtectedRoute allowedRoles={['super admin']}>
                                        <UserAccountList />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="account/info/:username"
                                element={
                                    <ProtectedRoute allowedRoles={['super admin']}>
                                        <UserAccountInfo />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="audit-trail"
                                element={
                                    <ProtectedRoute allowedRoles={['super admin']}>
                                        <AuditLogList />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="profile/info"
                                element={
                                    <ProtectedRoute allowedRoles={['super admin', 'employee', 'finance', 'management']}>
                                        <MyProfile />
                                    </ProtectedRoute>
                                }
                            />
                            <Route path="positions" element={<PositionList />} /> {/* Public route */}
                        </Route>
                        <Route path="unauthorized" element={<Unauthorized />} /> {/* Display unauthorized message */}
                    </Routes>
                </Router>
            </ThemeProvider>
        </UserProvider>
    )
}

export default App
