import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Stack,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    FormHelperText
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import React, { useState, useEffect } from 'react'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { createEmployee } from '../../services/EmployeeService'
import { getActivePositions } from '../../services/PositionService'

const EmployeeRegisterModal = ({ open, onClose, onRegisterSuccess }) => {
    const [errors, setErrors] = useState({})
    const [responseMessage, setResponseMessage] = useState('')
    const [showResponseDialog, setShowResponseDialog] = useState(false)
    const [loading, setLoading] = useState(false)
    const [newEmployee, setNewEmployee] = useState({})

    const [positions, setPositions] = useState([])

    useEffect(() => {
        const loadPositions = async () => {
            try {
                const result = await getActivePositions()
                console.log('loadPositions', result)
                setPositions(result || [])
            } catch (error) {
                console.error('Error fetching positions:', error)
            }
        }

        loadPositions()
    }, [])

    const validateFields = () => {
        const newErrors = {}
        const requiredFields = [
            'firstName',
            'lastName',
            'position',
            'dateHired',
            'birthdate',
            'contactNumber',
            'emailAddress',
            'address',
            'civilStatus',
            'sss',
            'philhealth',
            'pagibig',
            'tin',
            'motherFullName',
            'firstContactPerson',
            'firstContactPersonNo',
            'secondContactPerson',
            'secondContactPersonNo'
        ]

        requiredFields.forEach((field) => {
            if (!newEmployee[field]) {
                newErrors[field] = 'This field is required'
            }
        })

        setErrors(newErrors)
        return Object.keys(newErrors).length === 0
    }

    const handleRegisterInputChange = (event) => {
        const { name, value } = event.target
        if (name === 'position') {
            const selectedPosition = positions.find((pos) => pos.position === value)
            console.log('selectedPosition', selectedPosition)
            setNewEmployee((prevEmployee) => ({
                ...prevEmployee,
                position: {
                    position: selectedPosition.position.toUpperCase(),
                    timeIn: selectedPosition.timeIn,
                    timeOut: selectedPosition.timeOut
                }
            }))

            console.log('position', positions)
        } else {
            setNewEmployee((prevEmployee) => ({
                ...prevEmployee,
                [name]: ['dateHired', 'birthdate'].includes(name) ? value : value.toUpperCase()
            }))
        }
    }

    const handleFormSubmit = async (event) => {
        event.preventDefault()
        setLoading(true)

        if (validateFields()) {
            try {
                console.log('newEmployee', newEmployee)
                const result = await createEmployee(newEmployee)

                if (result.error) {
                    setResponseMessage(result.error)
                } else {
                    setResponseMessage(result.message)
                    onRegisterSuccess()
                }
                setShowResponseDialog(true)
            } catch (error) {
                setResponseMessage('An error occurred while recording attendance.')
                setShowResponseDialog(true)
            } finally {
                setLoading(false)
                onClose()
            }
        }
    }

    return (
        <>
            <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
                <DialogTitle>Register Employee</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleFormSubmit}>
                        <Stack spacing={2}>
                            {/* Form fields */}
                            <TextField
                                margin="normal"
                                name="employeeId"
                                label="Employee ID"
                                fullWidth
                                variant="outlined"
                                value={newEmployee.employeeId}
                                onChange={handleRegisterInputChange}
                            />
                            <TextField
                                margin="normal"
                                name="firstName"
                                label="First Name"
                                fullWidth
                                variant="outlined"
                                value={newEmployee.firstName}
                                onChange={handleRegisterInputChange}
                                error={Boolean(errors.firstName)}
                                helperText={errors.firstName}
                            />

                            <TextField
                                margin="normal"
                                name="middleName"
                                label="Middle Name"
                                fullWidth
                                variant="outlined"
                                value={newEmployee.middleName}
                                onChange={handleRegisterInputChange}
                                error={Boolean(errors.middleName)}
                                helperText={errors.middleName}
                            />

                            <TextField
                                margin="normal"
                                name="lastName"
                                label="Last Name"
                                fullWidth
                                variant="outlined"
                                value={newEmployee.lastName}
                                onChange={handleRegisterInputChange}
                                error={Boolean(errors.lastName)}
                                helperText={errors.lastName}
                            />

                            <FormControl fullWidth variant="outlined" margin="normal" error={Boolean(errors.position)}>
                                <InputLabel>Position</InputLabel>
                                <Select
                                    name="position"
                                    value={newEmployee.position}
                                    onChange={handleRegisterInputChange}
                                    label="Position"
                                >
                                    {positions.length > 0 ? (
                                        positions.map((positions) => (
                                            <MenuItem key={positions.id} value={positions.position}>
                                                {positions.position}
                                            </MenuItem>
                                        ))
                                    ) : (
                                        <MenuItem disabled>No employee available</MenuItem>
                                    )}
                                </Select>
                                {errors.employeeId && <FormHelperText>{errors.employeeId}</FormHelperText>}
                            </FormControl>
                            {/* Add other form fields and DatePicker fields as needed */}

                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="Date Hired"
                                    value={newEmployee.dateHired}
                                    onChange={(dateHired) =>
                                        handleRegisterInputChange({ target: { name: 'dateHired', value: dateHired } })
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            margin="normal"
                                            fullWidth
                                            variant="outlined"
                                            error={Boolean(errors.dateHired)}
                                            helperText={errors.dateHired}
                                        />
                                    )}
                                />
                            </LocalizationProvider>

                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="Birthdate"
                                    value={newEmployee.birthdate}
                                    onChange={(birthdate) =>
                                        handleRegisterInputChange({ target: { name: 'birthdate', value: birthdate } })
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            margin="normal"
                                            fullWidth
                                            variant="outlined"
                                            error={Boolean(errors.birthdate)}
                                            helperText={errors.birthdate}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                            <FormControl
                                fullWidth
                                variant="outlined"
                                margin="normal"
                                error={Boolean(errors.civilStatus)}
                            >
                                <InputLabel>Civil Status</InputLabel>
                                <Select
                                    name="civilStatus"
                                    value={newEmployee.civilStatus}
                                    onChange={handleRegisterInputChange}
                                    label="Civil Status"
                                >
                                    <MenuItem value="SINGLE">SINGLE</MenuItem>
                                    <MenuItem value="MARRIED">MARRIED</MenuItem>
                                    <MenuItem value="DIVORCED">DIVORCED</MenuItem>
                                    <MenuItem value="SEPARATED">SEPARATED</MenuItem>
                                    <MenuItem value="WIDOWED">WIDOWED</MenuItem>
                                </Select>
                                {errors.position && <FormHelperText>{errors.position}</FormHelperText>}
                            </FormControl>
                            <TextField
                                margin="normal"
                                name="contactNumber"
                                label="Contact No"
                                fullWidth
                                variant="outlined"
                                value={newEmployee.contactNumber}
                                onChange={handleRegisterInputChange}
                                error={Boolean(errors.contactNumber)}
                                helperText={errors.contactNumber}
                            />
                            <TextField
                                margin="normal"
                                name="emailAddress"
                                label="Email Address"
                                fullWidth
                                variant="outlined"
                                value={newEmployee.emailAddress}
                                onChange={handleRegisterInputChange}
                                error={Boolean(errors.emailAddress)}
                                helperText={errors.emailAddress}
                            />

                            <TextField
                                margin="normal"
                                name="address"
                                label="Home Address"
                                fullWidth
                                variant="outlined"
                                value={newEmployee.address}
                                onChange={handleRegisterInputChange}
                                error={Boolean(errors.address)}
                                helperText={errors.address}
                            />

                            <TextField
                                margin="normal"
                                name="sss"
                                label="SSS No"
                                fullWidth
                                variant="outlined"
                                value={newEmployee.sss}
                                onChange={handleRegisterInputChange}
                                error={Boolean(errors.sss)}
                                helperText={errors.sss}
                            />

                            <TextField
                                margin="normal"
                                name="tin"
                                label="Tin No"
                                fullWidth
                                variant="outlined"
                                value={newEmployee.tin}
                                onChange={handleRegisterInputChange}
                                error={Boolean(errors.tin)}
                                helperText={errors.tin}
                            />

                            <TextField
                                margin="normal"
                                name="pagibig"
                                label="Pagibig No"
                                fullWidth
                                variant="outlined"
                                value={newEmployee.pagibig}
                                onChange={handleRegisterInputChange}
                                error={Boolean(errors.pagibig)}
                                helperText={errors.pagibig}
                            />

                            <TextField
                                margin="normal"
                                name="philhealth"
                                label="Philhealth No"
                                fullWidth
                                variant="outlined"
                                value={newEmployee.philhealth}
                                onChange={handleRegisterInputChange}
                                error={Boolean(errors.philhealth)}
                                helperText={errors.philhealth}
                            />
                            <TextField
                                margin="normal"
                                name="motherFullName"
                                label="Mother's Full Maiden Name"
                                fullWidth
                                variant="outlined"
                                value={newEmployee.motherFullName}
                                onChange={handleRegisterInputChange}
                                error={Boolean(errors.motherFullName)}
                                helperText={errors.motherFullName}
                            />
                            <TextField
                                margin="normal"
                                name="firstContactPerson"
                                label="First Contact Person in Case of Emergency"
                                fullWidth
                                variant="outlined"
                                value={newEmployee.contactPerson}
                                onChange={handleRegisterInputChange}
                                error={Boolean(errors.contactPerson)}
                                helperText={errors.contactPerson}
                            />

                            <TextField
                                margin="normal"
                                name="firstContactPersonNo"
                                label="First Contact Person Number"
                                fullWidth
                                variant="outlined"
                                value={newEmployee.contactPersonNo}
                                onChange={handleRegisterInputChange}
                                error={Boolean(errors.contactPersonNo)}
                                helperText={errors.contactPersonNo}
                            />

                            <TextField
                                margin="normal"
                                name="secondContactPerson"
                                label="Second Contact Person in Case of Emergency"
                                fullWidth
                                variant="outlined"
                                value={newEmployee.contactPerson}
                                onChange={handleRegisterInputChange}
                                error={Boolean(errors.contactPerson)}
                                helperText={errors.contactPerson}
                            />

                            <TextField
                                margin="normal"
                                name="secondContactPersonNo"
                                label="Second Contact Person Number"
                                fullWidth
                                variant="outlined"
                                value={newEmployee.contactPersonNo}
                                onChange={handleRegisterInputChange}
                                error={Boolean(errors.contactPersonNo)}
                                helperText={errors.contactPersonNo}
                            />

                            <TextField
                                margin="normal"
                                name="bankAccount"
                                label="BPI Account No"
                                fullWidth
                                variant="outlined"
                                value={newEmployee.bankAccount}
                                onChange={handleRegisterInputChange}
                                error={Boolean(errors.bankAccount)}
                                helperText={errors.bankAccount}
                            />
                        </Stack>

                        <DialogActions style={{ marginTop: '16px' }}>
                            <Button onClick={onClose} color="secondary">
                                Cancel
                            </Button>
                            <Button type="submit" color="primary" variant="contained">
                                Register
                            </Button>
                        </DialogActions>
                    </form>
                </DialogContent>
            </Dialog>

            {/* Response Dialog */}
            <Dialog open={showResponseDialog} onClose={() => setShowResponseDialog(false)}>
                <DialogTitle>Response</DialogTitle>
                <DialogContent>
                    <p>{responseMessage}</p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowResponseDialog(false)} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default EmployeeRegisterModal
