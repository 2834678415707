import axios from 'axios'
import apiClient from '../clients/ApiClient' // Update this import path as needed

export const fetchUserAccount = async (filter) => {
    try {
        const response = await apiClient.get('/accounts/', { params: filter })
        return response.data
    } catch (error) {
        let errorMessage = 'Unexpected error occurred'

        if (axios.isAxiosError(error)) {
            if (error.response) {
                // Error response from backend
                errorMessage = error.response.data?.message || 'Unexpected error occurred.'
            } else if (error.message.includes('ERR_CONNECTION_REFUSED')) {
                // Handle connection refused explicitly
                errorMessage = 'Connection refused. Please check the backend server.'
            }
            console.error('Axios error:', error.response?.status, error.response?.data)
        } else {
            console.error('Unexpected error:', error)
            errorMessage = 'An unexpected error occurred: ' + error.message
        }

        return { error: errorMessage } // Return error message in an object
    }
}

export const createUserAccount = async (employeeDetails) => {
    try {
        const response = await apiClient.post('/accounts/create', employeeDetails)
        return response.data
    } catch (error) {
        let errorMessage = 'Unexpected error occurred'

        if (axios.isAxiosError(error)) {
            if (error.response) {
                // Error response from backend
                errorMessage = error.response.data?.message || 'Unexpected error occurred.'
            } else if (error.message.includes('ERR_CONNECTION_REFUSED')) {
                // Handle connection refused explicitly
                errorMessage = 'Connection refused. Please check the backend server.'
            }
            console.error('Axios error:', error.response?.status, error.response?.data)
        } else {
            console.error('Unexpected error:', error)
            errorMessage = 'An unexpected error occurred: ' + error.message
        }

        return { error: errorMessage } // Return error message in an object
    }
}

export const fetchUserAccountInfo = async (username) => {
    try {
        const response = await apiClient.get(`/accounts/${username}`) // Use backticks here
        console.log('response', response)
        return response.data
    } catch (error) {
        let errorMessage = 'Unexpected error occurred'

        if (axios.isAxiosError(error)) {
            if (error.response) {
                // Error response from backend
                errorMessage = error.response.data?.message || 'Unexpected error occurred.'
            } else if (error.message.includes('ERR_CONNECTION_REFUSED')) {
                // Handle connection refused explicitly
                errorMessage = 'Connection refused. Please check the backend server.'
            }
            console.error('Axios error:', error.response?.status, error.response?.data)
        } else {
            console.error('Unexpected error:', error)
            errorMessage = 'An unexpected error occurred: ' + error.message
        }

        return { error: errorMessage } // Return error message in an object
    }
}

export const updateStatus = async (request) => {
    try {
        const response = await apiClient.put('/accounts/update-status', request) // Use backticks here
        console.log('response', response)
        return response.data
    } catch (error) {
        let errorMessage = 'Unexpected error occurred'

        if (axios.isAxiosError(error)) {
            if (error.response) {
                // Error response from backend
                errorMessage = error.response.data?.message || 'Unexpected error occurred.'
            } else if (error.message.includes('ERR_CONNECTION_REFUSED')) {
                // Handle connection refused explicitly
                errorMessage = 'Connection refused. Please check the backend server.'
            }
            console.error('Axios error:', error.response?.status, error.response?.data)
        } else {
            console.error('Unexpected error:', error)
            errorMessage = 'An unexpected error occurred: ' + error.message
        }

        return { error: errorMessage } // Return error message in an object
    }
}

export const updatePassword = async (request) => {
    try {
        const response = await apiClient.put('/accounts/update-password', request) // Use backticks here
        console.log('response', response)
        return response.data
    } catch (error) {
        let errorMessage = 'Unexpected error occurred'

        if (axios.isAxiosError(error)) {
            if (error.response) {
                // Error response from backend
                errorMessage = error.response.data?.message || 'Unexpected error occurred.'
            } else if (error.message.includes('ERR_CONNECTION_REFUSED')) {
                // Handle connection refused explicitly
                errorMessage = 'Connection refused. Please check the backend server.'
            }
            console.error('Axios error:', error.response?.status, error.response?.data)
        } else {
            console.error('Unexpected error:', error)
            errorMessage = 'An unexpected error occurred: ' + error.message
        }

        return { error: errorMessage } // Return error message in an object
    }
}
