import React, { useState, useEffect, useRef } from 'react'
import {
    Paper,
    Tabs,
    Tab,
    TextField,
    Stack,
    Button,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent
} from '@mui/material'
import { useParams } from 'react-router-dom'
import { fetchEmployeeInfo, updateEmployeeWorkingTime } from '../../services/EmployeeService'
import { QRCodeCanvas } from 'qrcode.react'
// Reusable TextField Component
const EmployeeTextField = ({ label, value, name, onChange, error, helperText, type = 'text', disabled = false }) => (
    <TextField
        label={label}
        value={value}
        name={name}
        onChange={onChange}
        error={Boolean(error)}
        helperText={helperText}
        fullWidth
        variant="outlined"
        type={type}
        disabled={disabled}
    />
)

const EmployeeInfo = () => {
    const { employeeId } = useParams()
    const [employee, setEmployeeData] = useState(null)
    const [tabIndex, setTabIndex] = useState(0)
    const [errors, setErrors] = useState({})
    const [responseMessage, setResponseMessage] = useState('')
    const [showResponseDialog, setShowResponseDialog] = useState(false)
    const [loading, setLoading] = useState(false)

    const canvasRef = useRef(null)

    const handleDownload = () => {
        const canvas = document.querySelector('canvas')
        if (canvas) {
            const url = canvas.toDataURL('image/png')
            const link = document.createElement('a')
            link.href = url
            link.download = `${employee.employeeId}_${employee.firstName}_${employee.lastName}`
            link.click()
        } else {
            console.error('Canvas not found')
        }
    }

    // Fetch employee data on mount
    useEffect(() => {
        const fetchEmployeeData = async () => {
            const data = await fetchEmployeeInfo(employeeId)
            setEmployeeData(data)
        }
        fetchEmployeeData()
    }, [employeeId])

    // Tab change handler
    const handleTabChange = (event, newIndex) => {
        setTabIndex(newIndex)
    }

    // Form input change handler
    const handleRegisterInputChange = (event) => {
        const { name, value } = event.target
        setEmployeeData((prev) => ({
            ...prev,
            employeeSchedule: {
                ...prev.employeeSchedule,
                [name]: value
            }
        }))
    }

    // Submit handler for working schedule
    const updateWorkingTimeSchedule = async (event) => {
        event.preventDefault()
        setLoading(true)
        try {
            const updateRecord = {
                employeeId: employee.employeeId,
                timeIn: employee.employeeSchedule.timeIn,
                timeOut: employee.employeeSchedule.timeOut
            }
            const result = await updateEmployeeWorkingTime(updateRecord)
            setResponseMessage(result.error ? result.error : result.message)
            setShowResponseDialog(true)
        } catch (error) {
            console.error(error)
            setResponseMessage('An error occurred while recording attendance.')
            setShowResponseDialog(true)
        } finally {
            setLoading(false)
        }
    }

    if (!employee) {
        return <div>Loading...</div>
    }

    return (
        <div className="bg-white shadow-md rounded-lg p-6 max-w mx-auto">
            <h2 className="text-2xl font-semibold mb-4">Employee Details</h2>
            <Paper square>
                <Tabs value={tabIndex} onChange={handleTabChange} indicatorColor="primary" textColor="primary" centered>
                    <Tab label="Personal Details" />
                    <Tab label="QR Code" />
                    <Tab label="Emergency Contact" />
                    <Tab label="Government Mandate IDs" />
                    <Tab label="User Account" />
                    <Tab label="Working Schedule" />
                </Tabs>
            </Paper>
            <div className="mt-4">
                {/* Personal Details Tab */}
                {tabIndex === 0 && (
                    <Stack spacing={3} width={'60%'} margin="auto">
                        <EmployeeTextField label="Employee ID" value={employee.employeeId} disabled />
                        <EmployeeTextField label="First Name" value={employee.firstName} />
                        <EmployeeTextField label="Middle Name" value={employee.middleName} />
                        <EmployeeTextField label="Last Name" value={employee.lastName} />
                        <EmployeeTextField label="Address" value={employee.address} />
                        <EmployeeTextField label="Contact Number" value={employee.contactNumber} />
                        <EmployeeTextField label="Email" value={employee.emailAddress} />
                        <EmployeeTextField label="Position" value={employee.position} />
                        <EmployeeTextField
                            label="Birthdate"
                            value={new Date(employee.birthdate).toLocaleDateString()}
                        />
                        <EmployeeTextField label="Civil Status" value={employee.civilStatus} />
                        <EmployeeTextField
                            label="Date Hired"
                            value={new Date(employee.dateHired).toLocaleDateString()}
                        />
                        {/* <h3 className="text-xl font-semibold mt-6 mb-2">Emergency Contact</h3>
                        <EmployeeTextField label="Full Name" value={employee.employeeContactPerson.fullName} />
                        <EmployeeTextField label="Contact Number" value={employee.employeeContactPerson.contactNo} /> */}
                    </Stack>
                )}
                {tabIndex === 1 && (
                    <Stack spacing={3} width="100%" margin="auto" alignItems="center">
                        <QRCodeCanvas
                            value={employee.qrCode}
                            size={256}
                            bgColor="#ffffff"
                            fgColor="#000000"
                            level="L"
                            includeMargin={true}
                        />

                        <Button onClick={handleDownload} color="primary" variant="contained" disabled={loading}>
                            {loading ? 'Updating...' : 'Download QR Code'}
                        </Button>
                    </Stack>
                )}
                {tabIndex === 2 && (
                    <Stack spacing={3} width={'60%'} margin="auto">
                        <EmployeeTextField label="Full Name" value={employee.employeeContactPersons[0]?.fullName} />
                        <EmployeeTextField label="Contact No." value={employee.employeeContactPersons[0]?.contactNo} />
                        <EmployeeTextField label="Full Name" value={employee.employeeContactPersons[1]?.fullName} />
                        <EmployeeTextField label="Contact No." value={employee.employeeContactPersons[1]?.contactNo} />
                    </Stack>
                )}
                {/* Government Mandate IDs Tab */}
                {tabIndex === 3 && (
                    <Stack spacing={3} width={'60%'} margin="auto">
                        <EmployeeTextField label="SSS" value={employee.govermentMandated?.sss} />
                        <EmployeeTextField label="TIN" value={employee.govermentMandated?.tin} />
                        <EmployeeTextField label="Pagibig" value={employee.govermentMandated?.pagibig} />
                        <EmployeeTextField label="Philhealth" value={employee.govermentMandated?.philhealth} />
                    </Stack>
                )}
                {/* User Account Tab */}
                {tabIndex === 4 && (
                    <Stack spacing={3} width={'60%'} margin="auto">
                        <EmployeeTextField label="Username" value={employee.userAccount?.username} />
                        <EmployeeTextField label="Password" type="password" value={employee.userAccount?.password} />
                        <EmployeeTextField label="User Role" value={employee.userAccount?.userRole.role} />
                    </Stack>
                )}
                {/* Working Schedule Tab */}
                {tabIndex === 5 && (
                    <form onSubmit={updateWorkingTimeSchedule}>
                        <Stack spacing={3} width={'60%'} margin="auto">
                            <EmployeeTextField
                                label="Time In"
                                name="timeIn"
                                type="time"
                                value={employee.employeeSchedule?.timeIn}
                                onChange={handleRegisterInputChange}
                                error={errors.timeIn}
                                helperText={errors.timeIn}
                            />
                            <EmployeeTextField
                                label="Time Out"
                                name="timeOut"
                                type="time"
                                value={employee.employeeSchedule?.timeOut}
                                onChange={handleRegisterInputChange}
                                error={errors.timeOut}
                                helperText={errors.timeOut}
                            />
                            <Button type="submit" color="primary" variant="contained" disabled={loading}>
                                {loading ? 'Updating...' : 'Update Working Time'}
                            </Button>
                        </Stack>
                    </form>
                )}
            </div>

            {/* Response Dialog */}
            <Dialog open={showResponseDialog} onClose={() => setShowResponseDialog(false)}>
                <DialogTitle>Response</DialogTitle>
                <DialogContent>
                    <p>{responseMessage}</p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowResponseDialog(false)} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default EmployeeInfo
