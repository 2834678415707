import React, { useState, useEffect, useRef } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { Paper } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { fetchAuditLogs } from '../../services/AuditLogService'

const Status = {
    ACTIVE: 'ACTIVE',
    DEACTIVATED: 'DEACTIVATED'
}

const AuditLogList = () => {
    const navigate = useNavigate() // Initialize navigate
    const DEFAULT_PAGE_SIZE = 10
    const [dtr, setDTR] = useState([])
    const [loading, setLoading] = useState(false)
    const [selectionModel, setSelectionModel] = useState([])
    const [recordType, setRecordType] = useState(Status.PENDING)
    const [searchTerm, setSearchTerm] = useState(null)
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE)
    const [totalCount, setTotalCount] = useState(0)
    const [refresh, setRefresh] = useState(false)
    const [responseMessage, setResponseMessage] = useState('')
    const [registerDialogOpen, setRegisterDialogOpen] = useState(false)

    const columns = [
        { field: 'userId', headerName: 'Employee Id', flex: 1 },
        { 
            field: 'timestamp', 
            headerName: 'Timestamp', 
            flex: 2,
            renderCell: (params) => {
                const formattedTime = new Date(params.value).toLocaleString('en-US', {
                    timeZone: 'Asia/Manila', // Adjust for your timezone
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                });
                return <span>{formattedTime}</span>;
            }
        },
        { 
            field: 'action', 
            headerName: 'Activity', 
            flex: 7, 
            renderCell: (params) => (
              <span style={{ textTransform: 'uppercase' }}>
                {params.value}
              </span>
            ) 
          },
    ]

    useEffect(() => {
        fetchEmployeeList(recordType, searchTerm, page, pageSize)
    }, [recordType, page, pageSize, refresh])

    const fetchEmployeeList = async (recordType, search, page, limit) => {
        setLoading(true)
        try {
            const filter = {
                page,
                limit,
                search: search
            }
            let response = await fetchAuditLogs(filter)
            const dailyRecords = response.data || []
            setTotalCount(response.count || 0)
            const formattedData = dailyRecords.map((record) => ({
                id: record.id,
                timestamp: record.timestamp,
                action: record.action,
                userId: record.userId,
            }))
            setDTR(formattedData)
        } catch (error) {
            console.error('Error fetching DTR data:', error)
        } finally {
            setLoading(false)
        }
    }

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value)
    }

    

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            fetchEmployeeList(recordType, searchTerm, page, pageSize); 
        }
    };

    const handleCloseRegisterDialog = () => {
        setRegisterDialogOpen(false)
    }

    const handleRowClick = (params) => {
        // const employeeId = `${params.row.dateHired}-${params.row.employeeId}-${params.row.id}`
        // navigate(`/employee/info/${employeeId}`) // Navigate to the employee info page with ID
    }

    return (
        <div className="flex flex-col items-center justify-center p-4">
            <div className="flex items-center justify-between w-full mb-4">
                <h1 className="text-xl font-bold text-black">Audit Logs</h1>
            </div>

            {loading && <p>Loading...</p>}
            {responseMessage && <p className="text-green-500">{responseMessage}</p>}

            {/* Search and Register Button */}
            <div className="flex space-x-4 mb-4">
                <input
                    type="text"
                    placeholder="Search..."
                    className="p-2 border rounded w-full"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    onKeyDown={handleKeyDown} 
                   
                />

               
            </div>

            
            <Paper sx={{ width: '100%', height: 'calc(100vh - 250px)', minWidth: 300 }}>
                <DataGrid
                    rows={dtr}
                    columns={columns} 
                    loading={loading}
                    page={page - 1}
                    pageSize={pageSize}
                    rowCount={totalCount}
                    paginationMode="server"
                    initialState={{
                        ...dtr.initialState,
                        pagination: { paginationModel: { pageSize: DEFAULT_PAGE_SIZE } }
                    }}
                    pageSizeOptions={[5, 10, 25]}
                    onPaginationModelChange={(newPaginationModel) => {
                        setPage(newPaginationModel.page + 1)
                        setPageSize(newPaginationModel.pageSize)
                    }}
                    onRowSelectionModelChange={(newSelectionModel) => setSelectionModel(newSelectionModel)}
                    selectionModel={selectionModel}
                    // onRowClick={handleRowClick}
                    sx={{
                        border: 0,
                        '& .MuiDataGrid-columnHeaders': {
                            fontWeight: 'bold'
                        },
                        '& .MuiDataGrid-cell': {
                            cursor: 'default'
                        }
                    }}
                />
            </Paper>
        </div>
    )
}

export default AuditLogList
